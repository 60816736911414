import i18next from "i18next";
import { checkVersion, getStorage } from "../../utils";
import constant from "../../constant";
import { useEffect, useState } from "react";
import ModalWindow from "./ModalWindow";
import Shimmer from "./Shimmer";

function ShowVersionHistory(props: any) {
    const [showModal, setShowModal] = useState(false);
    const [text, setText]: any = useState(undefined);
    const onShowModal = () => {
        setShowModal(true);

        fetch('/version.txt')
            .then(r => r.text())
            .then((text: any) => {
                setText(text);
                console.log(text);
            });
    }
    const onHideModal = () => {
        setShowModal(false);
    }
    return (
        <>
            {showModal && <ModalWindow
                title="Version History"
                backdrop={false}
                css="modal-lg"
                onHideModal={onHideModal}
            >
                {text
                    ? <div className="" style={{ whiteSpace: "pre" }} dangerouslySetInnerHTML={{ __html: text.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;').replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
                    : <Shimmer />
                }

            </ModalWindow>
            }
            <button type="button" onClick={onShowModal} className="btn btn-link p-0">{props.children}</button>
        </>
    )
}

function Footer(props: any) {
    const { showSideBar } = props;
    const footerCSS = showSideBar ? "mt-auto py-3 bg-white text-center" : "footer mt-auto py-3 bg-white text-center";
    const version = getStorage(constant.key.version);
    useEffect(() => {
        checkVersion();
    }, []);


    // useEffect(() => {
    //     const scriptDefaultMenu = document.createElement('script');
    //     scriptDefaultMenu.src = "/assets/js/defaultmenu.min.js";
    //     scriptDefaultMenu.async = false;
    //     document.body.appendChild(scriptDefaultMenu);

    //     const scriptSimpleBarLib = document.createElement('script');
    //     scriptSimpleBarLib.src = "/assets/libs/simplebar/simplebar.min.js";
    //     scriptSimpleBarLib.async = false;
    //     document.body.appendChild(scriptSimpleBarLib);

    //     const scriptSimpleBar = document.createElement('script');
    //     scriptSimpleBar.src = "/assets/js/simplebar.js";
    //     scriptSimpleBar.async = false;
    //     document.body.appendChild(scriptSimpleBar);

    //     return () => {
    //         document.body.removeChild(scriptDefaultMenu);
    //         document.body.removeChild(scriptSimpleBarLib);
    //         document.body.removeChild(scriptSimpleBar);
    //     }
    // }, []);

    return (
        <footer className={footerCSS}>
            <div className="container">
                <span className="text-muted">
                    {i18next.t("footer.copyright")} © <span id="year">{new Date().getFullYear()}</span>
                    <a href="javascript:void(0);" className="text-dark fw-semibold me-2 ms-2">{i18next.t("footer.woce")}</a>
                    {i18next.t("footer.all_rights_reserved")}
                </span>
                <span className="ms-3">
                    {i18next.t("footer.version")} <ShowVersionHistory>{version}</ShowVersionHistory>
                </span>
            </div>
        </footer>
    )
}

export function VersionFooter() {
    const version = getStorage(constant.key.version);
    useEffect(() => {
        checkVersion();
    }, []);
    return (
        <div className="text-muted fw-normal text-center mt-3">
            {i18next.t("footer.version")} {version}
        </div>
    )
}

export default Footer