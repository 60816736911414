import axios from 'axios';
import constant from '../constant';
import { getAuthHeader, setStorage } from '../utils';

const setTokenDetail = (result: any, rememberMe: boolean) => {
    // setStorage(constant.key.rememberMe, rememberMe);
    setStorage(constant.key.userDetail, result.user_detail);
    setStorage(constant.key.token, result.token);
    setStorage(constant.key.permission, result.user_permission_list);
    setStorage(constant.key.activeBranch, result.user_permission_list[0].branch_id);
}

export const login = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/auth/login`;
    return axios.post(url, model).then((res: any) => {
        if (res.data.result) {
            setTokenDetail(res.data.result, model.rememberMe)
        }
        return res.data;
    });
}

export const register = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/auth/register`;
    return axios.post(url, model).then((res: any) => {
        if (res.data.result) {
            setTokenDetail(res.data.result, false)
        }
        return res.data;
    });
}

export const changePassword = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/auth/change-password`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            return response.data;
        });
}

export const adminChangePassword = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/auth/admin-change-password`;
    return axios.post(url, model, getAuthHeader())
        .then((res: any) => {
            return res.data;
        });
}