import React from 'react';
import { removeStorage } from './../../utils';
import constant from './../../constant';

class Logout extends React.Component<any, any> {
    componentDidMount() {
        document.title = 'Logout';

        removeStorage('rsc_cache')
        // remove all storage keys except language
        removeStorage(constant.key.token);
        removeStorage(constant.key.userDetail);
        removeStorage(constant.key.permission);
        removeStorage(constant.key.activeBranch);
        // localStorage.clear();

        window.location.href = constant.component.login.url;
    }

    public render() {
        return (
            <React.Fragment>
                <div className="text-center">Redirecting to home page</div>
            </React.Fragment>
        );
    }
}

export default Logout;