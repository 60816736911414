import React, { Suspense } from "react";
import { Navigate, BrowserRouter, Routes, Route, } from "react-router-dom";
import constant from "./constant";
import Wrapper from "./component/common/Wrapper";

import Login from "./container/auth/Login";
import Register from "./container/auth/Register";
import Logout from "./container/auth/Logout";
import ComingSoon from "./container/misc/ComingSoon";
import FourZeroFour from "./container/misc/FourZeroFour";

import Welcome from "./container/misc/Welcome";


// ghg 
// import OrganisationEmissionDashboard from "./container/ghg/dashboard/OrganisationEmission";
// import BranchEmissionDashboard from "./container/ghg/dashboard/BranchEmission";

// import StationaryEmission from "./container/ghg/scope-1/StationaryEmission";
// import MobileCombustionEmission from "./container/ghg/scope-1/MobileCombustion";
// import Fugitive1Emission from "./container/ghg/scope-1/Fugitive1Emission";
// import Fugitive2Emission from "./container/ghg/scope-1/Fugitive2Emission";
// import Fugitive3Emission from "./container/ghg/scope-1/Fugitive3Emission";
// import ElectricityEmission from "./container/ghg/scope-2/Electricity";
// import Category1Emission from "./container/ghg/scope-3/Category-1";
// import Category2Emission from "./container/ghg/scope-3/Category-2";
// import Category3Emission from "./container/ghg/scope-3/Category-3";
// import Category4Emission from "./container/ghg/scope-3/Category-4";
// import Category5Emission from "./container/ghg/scope-3/Category-5";
// import Category6FlightEmission from "./container/ghg/scope-3/Category-6-Flight";
// import Category6RailEmission from "./container/ghg/scope-3/Category-6-Rail";
// import Category6CarEmission from "./container/ghg/scope-3/Category-6-Car";
// import Category6HotelEmission from "./container/ghg/scope-3/Category-6-Hotel";
// import Category7Emission from "./container/ghg/scope-3/Category-7";
// import Category8Emission from "./container/ghg/scope-3/Category-8";
// import Category11Emission from "./container/ghg/scope-3/Category-11";
// import Category12Emission from "./container/ghg/scope-3/Category-12";
// ghg 

// //esg
// import ESGDetail from "./container/esg/Detail";
// import ESGChartTemplate from "./container/esg/ChartTemplate";

// import ESGEnvironmentEnergyDashboard from "./container/esg/environment/dashboard/EnergyDashboard";
// import ESGEnvironmentWasteDashboard from "./container/esg/environment/dashboard/WasteDashboard";
// import ESGEnvironmentWaterDashboard from "./container/esg/environment/dashboard/WaterDashboard";

// import ESGSocialDiversityDashboard from "./container/esg/social/dashboard/DiversityDashboard";
// import ESGSocialEmployementDashboard from "./container/esg/social/dashboard/EmployementDashboard";
// import ESGSocialOccupationalDashboard from "./container/esg/social/dashboard/OccupationalDashboard";

// import ESGGovernanceSummaryDashboard from "./container/esg/governance/dashboard/SummaryDashboard";
// // esg






// // compliance
// import ComplianceGRI from "./container/compliance/GRI";
// // compliance

// import OffsetMarketPlace from "./container/offset/marketplace/List";
// import OffsetCart from "./container/offset/marketplace/Cart";
// import OffsetCheckout from "./container/offset/marketplace/Checkout";
// import OffsetPurchaseHistory from "./container/offset/marketplace/PurchaseHistory";
// import OffsetFavourite from "./container/offset/marketplace/Favourite";
// import OffsetDetail from "./container/offset/marketplace/Detail";



// import SupportTicketList from "./container/support-ticket/List";
// import SupportTicketAdd from "./container/support-ticket/Add";
// import SupportTicketDetail from "./container/support-ticket/Detail";

// user
import Profile from "./container/user/Profile";
// user

// import SettingsMasterDataFuelType from "./container/settings/master-data/FuelType";
// import SettingsMasterDataGasType from "./container/settings/master-data/GasType";
// import SettingsMasterDataWasteType from "./container/settings/master-data/WasteType";
// import SettingsMasterDataTransportation from "./container/settings/master-data/Transportation";
// import SettingsMasterDataGoodsAndServices from "./container/settings/master-data/GoodsAndServices";
// import SettingsMasterDataElectricity from "./container/settings/master-data/Electricity";
// import SettingsMasterDataHotel from "./container/settings/master-data/Hotel";

// import SettingsEmissionSourceScope1 from "./container/settings/emission-source/Scope1";
// import SettingsEmissionSourceScope2 from "./container/settings/emission-source/Scope2";
// import SettingsEmissionSourceScope3 from "./container/settings/emission-source/Scope3";

// import SettingsConfigPhysicalLocation from "./container/settings/config/PhysicalLocation";
// import SettingsConfigReportingLocation from "./container/settings/config/ReportingLocation";
// import SettingsConfigSupplier from "./container/settings/config/Supplier";

// import SettingsEmissionFactorElectricity from "./container/settings/emission-factor/Electricity";
// import SettingsEmissionFactorFuelType from "./container/settings/emission-factor/FuelType";
// import SettingsEmissionFactorGasType from "./container/settings/emission-factor/GasType";
// import SettingsEmissionFactorGoodsAndServices from "./container/settings/emission-factor/GoodsAndServices";
// import SettingsEmissionFactorTransportation from "./container/settings/emission-factor/Transportation";
// import SettingsEmissionFactorWasteType from "./container/settings/emission-factor/WasteType";

// import GreenAPIMasterDataFuelType from "./container/settings/green-api/master-data/FuelType";
// import GreenAPIMasterDataGasType from "./container/settings/green-api/master-data/GasType";
// import GreenAPIMasterDataWasteType from "./container/settings/green-api/master-data/WasteType";
// import GreenAPIMasterDataTransportation from "./container/settings/green-api/master-data/Transportation";
// import GreenAPIMasterDataElectricity from "./container/settings/green-api/master-data/Electricity";
// import GreenAPIMasterDataGoodsAndServices from "./container/settings/green-api/master-data/GoodsAndServices";
// import GreenAPIMasterDataHotel from "./container/settings/green-api/master-data/Hotel";

// import GreenAPIEmissionFactorFuelType from "./container/settings/green-api/emission-factor/FuelType";
// import GreenAPIEmissionFactorGasType from "./container/settings/green-api/emission-factor/GasType";
// import GreenAPIEmissionFactorWasteType from "./container/settings/green-api/emission-factor/WasteType";
// import GreenAPIEmissionFactorTransportation from "./container/settings/green-api/emission-factor/Transportation";
// import GreenAPIEmissionFactorElectricity from "./container/settings/green-api/emission-factor/Electricity";
// import GreenAPIEmissionFactorGoodsAndServices from "./container/settings/green-api/emission-factor/GoodsAndServices";
// import GreenAPIEmissionFactorHotel from "./container/settings/green-api/emission-factor/Hotel";

// import ManageRole from "./container/settings/user-management/ManageRole";
// import ManageUser from "./container/settings/user-management/ManageUser";
// import ManageCompanyBranch from "./container/settings/user-management/ManageCompanyBranch";

// brsr
// import BRSRList from './container/brsr/List';
// import BRSRDetail from './container/brsr/Detail';
// import BRSRPrintDetail from './container/brsr/Print';
// brsr

import { getStorage } from "./utils";

// support
const SupportTicketList = React.lazy(() => import("./container/support-ticket/List"));
const SupportTicketAdd = React.lazy(() => import("./container/support-ticket/Add"));
const SupportTicketDetail = React.lazy(() => import("./container/support-ticket/Detail"));
// support

// compliance
const ComplianceGRI = React.lazy(() => import("./container/compliance/GRI"));
// compliance

//esg
const ESGDetail = React.lazy(() => import("./container/esg/Detail"));
const ESGCaptureDetail = React.lazy(() => import("./container/esg/Capture"));
const ESGChartTemplate = React.lazy(() => import("./container/esg/ChartTemplate"));

const ESGEnvironmentEnergyDashboard = React.lazy(() => import("./container/esg/environment/dashboard/EnergyDashboard"));
const ESGEnvironmentWasteDashboard = React.lazy(() => import("./container/esg/environment/dashboard/WasteDashboard"));
const ESGEnvironmentWaterDashboard = React.lazy(() => import("./container/esg/environment/dashboard/WaterDashboard"));

const ESGSocialDiversityDashboard = React.lazy(() => import("./container/esg/social/dashboard/DiversityDashboard"));
const ESGSocialEmployementDashboard = React.lazy(() => import("./container/esg/social/dashboard/EmployementDashboard"));
const ESGSocialOccupationalDashboard = React.lazy(() => import("./container/esg/social/dashboard/OccupationalDashboard"));

const ESGGovernanceSummaryDashboard = React.lazy(() => import("./container/esg/governance/dashboard/SummaryDashboard"));

const ESGMaterialityConfigMaterialProfile = React.lazy(() => import("./container/esg/materiality-config/MaterialProfile"));
const ESGMaterialityConfigMaterialQuestion = React.lazy(() => import("./container/esg/materiality-config/MaterialQuestion"));
const ESGMaterialityConfigCategoryList = React.lazy(() => import("./container/esg/materiality-config/CategoryList"));
const ESGMaterialityConfigTopicList = React.lazy(() => import("./container/esg/materiality-config/TopicList"));
const ESGMaterialityConfigSubTopicList = React.lazy(() => import("./container/esg/materiality-config/SubTopicList"));
const ESGMaterialityConfigQuestionList = React.lazy(() => import("./container/esg/materiality-config/QuestionList"));
// esg

// decarbonization
const DecarbonizationActionLibrary = React.lazy(() => import("./container/decarbonization/ActionLibrary"));
const DecarbonizationNetZeroGoalTracker = React.lazy(() => import("./container/decarbonization/NetZeroGoalTracker"));
const DecarbonizationNetZeroRoadmap = React.lazy(() => import("./container/decarbonization/NetZeroRoadmap"));
// decarbonization

// offset
const OffsetMarketPlace = React.lazy(() => import("./container/offset/marketplace/List"));
const OffsetCart = React.lazy(() => import("./container/offset/marketplace/Cart"));
const OffsetCheckout = React.lazy(() => import("./container/offset/marketplace/Checkout"));
const OffsetPurchaseHistory = React.lazy(() => import("./container/offset/marketplace/PurchaseHistory"));
const OffsetFavourite = React.lazy(() => import("./container/offset/marketplace/Favourite"));
const OffsetDetail = React.lazy(() => import("./container/offset/marketplace/Detail"));
// offset

// ghg
const OrganisationEmissionDashboard = React.lazy(() => import("./container/ghg/dashboard/OrganisationEmission"));
const BranchEmissionDashboard = React.lazy(() => import("./container/ghg/dashboard/BranchEmission"));

const StationaryEmission = React.lazy(() => import("./container/ghg/scope-1/StationaryEmission"));
const MobileCombustionEmission = React.lazy(() => import("./container/ghg/scope-1/MobileCombustion"));
const Fugitive1Emission = React.lazy(() => import("./container/ghg/scope-1/Fugitive1Emission"));
const Fugitive2Emission = React.lazy(() => import("./container/ghg/scope-1/Fugitive2Emission"));
const Fugitive3Emission = React.lazy(() => import("./container/ghg/scope-1/Fugitive3Emission"));
const ElectricityEmission = React.lazy(() => import("./container/ghg/scope-2/Electricity"));
const Category1Emission = React.lazy(() => import("./container/ghg/scope-3/Category-1"));
const Category2Emission = React.lazy(() => import("./container/ghg/scope-3/Category-2"));
const Category3Emission = React.lazy(() => import("./container/ghg/scope-3/Category-3"));
const Category4Emission = React.lazy(() => import("./container/ghg/scope-3/Category-4"));
const Category5Emission = React.lazy(() => import("./container/ghg/scope-3/Category-5"));
const Category6FlightEmission = React.lazy(() => import("./container/ghg/scope-3/Category-6-Flight"));
const Category6RailEmission = React.lazy(() => import("./container/ghg/scope-3/Category-6-Rail"));
const Category6CarEmission = React.lazy(() => import("./container/ghg/scope-3/Category-6-Car"));
const Category6HotelEmission = React.lazy(() => import("./container/ghg/scope-3/Category-6-Hotel"));
const Category7Emission = React.lazy(() => import("./container/ghg/scope-3/Category-7"));
const Category8Emission = React.lazy(() => import("./container/ghg/scope-3/Category-8"));
const Category11Emission = React.lazy(() => import("./container/ghg/scope-3/Category-11"));
const Category12Emission = React.lazy(() => import("./container/ghg/scope-3/Category-12"));
// ghg

// user mgmt
const ManageRole = React.lazy(() => import("./container/settings/user-management/ManageRole"));
const ManageUser = React.lazy(() => import("./container/settings/user-management/ManageUser"));
const ManageCompanyBranch = React.lazy(() => import("./container/settings/user-management/ManageCompanyBranch"));
// user mgmt

// green api
const GreenAPIMasterDataFuelType = React.lazy(() => import("./container/settings/green-api/master-data/FuelType"));
const GreenAPIMasterDataGasType = React.lazy(() => import("./container/settings/green-api/master-data/GasType"));
const GreenAPIMasterDataWasteType = React.lazy(() => import("./container/settings/green-api/master-data/WasteType"));
const GreenAPIMasterDataTransportation = React.lazy(() => import("./container/settings/green-api/master-data/Transportation"));
const GreenAPIMasterDataElectricity = React.lazy(() => import("./container/settings/green-api/master-data/Electricity"));
const GreenAPIMasterDataGoodsAndServices = React.lazy(() => import("./container/settings/green-api/master-data/GoodsAndServices"));
const GreenAPIMasterDataHotel = React.lazy(() => import("./container/settings/green-api/master-data/Hotel"));

const GreenAPIEmissionFactorFuelType = React.lazy(() => import("./container/settings/green-api/emission-factor/FuelType"));
const GreenAPIEmissionFactorGasType = React.lazy(() => import("./container/settings/green-api/emission-factor/GasType"));
const GreenAPIEmissionFactorWasteType = React.lazy(() => import("./container/settings/green-api/emission-factor/WasteType"));
const GreenAPIEmissionFactorTransportation = React.lazy(() => import("./container/settings/green-api/emission-factor/Transportation"));
const GreenAPIEmissionFactorElectricity = React.lazy(() => import("./container/settings/green-api/emission-factor/Electricity"));
const GreenAPIEmissionFactorGoodsAndServices = React.lazy(() => import("./container/settings/green-api/emission-factor/GoodsAndServices"));
const GreenAPIEmissionFactorHotel = React.lazy(() => import("./container/settings/green-api/emission-factor/Hotel"));
// green api

// emission source
const SettingsEmissionSourceScope1 = React.lazy(() => import("./container/settings/emission-source/Scope1"));
const SettingsEmissionSourceScope2 = React.lazy(() => import("./container/settings/emission-source/Scope2"));
const SettingsEmissionSourceScope3 = React.lazy(() => import("./container/settings/emission-source/Scope3"));
// emission source

// config
const SettingsConfigPhysicalLocation = React.lazy(() => import("./container/settings/config/PhysicalLocation"));
const SettingsConfigReportingLocation = React.lazy(() => import("./container/settings/config/ReportingLocation"));
const SettingsConfigSupplier = React.lazy(() => import("./container/settings/config/Supplier"));
// config

// emission factor
const SettingsEmissionFactorElectricity = React.lazy(() => import("./container/settings/emission-factor/Electricity"));
const SettingsEmissionFactorFuelType = React.lazy(() => import("./container/settings/emission-factor/FuelType"));
const SettingsEmissionFactorGasType = React.lazy(() => import("./container/settings/emission-factor/GasType"));
const SettingsEmissionFactorGoodsAndServices = React.lazy(() => import("./container/settings/emission-factor/GoodsAndServices"));
const SettingsEmissionFactorTransportation = React.lazy(() => import("./container/settings/emission-factor/Transportation"));
const SettingsEmissionFactorWasteType = React.lazy(() => import("./container/settings/emission-factor/WasteType"));
// emission factor

// master data
const SettingsMasterDataFuelType = React.lazy(() => import("./container/settings/master-data/FuelType"));
const SettingsMasterDataGasType = React.lazy(() => import("./container/settings/master-data/GasType"));
const SettingsMasterDataWasteType = React.lazy(() => import("./container/settings/master-data/WasteType"));
const SettingsMasterDataTransportation = React.lazy(() => import("./container/settings/master-data/Transportation"));
const SettingsMasterDataGoodsAndServices = React.lazy(() => import("./container/settings/master-data/GoodsAndServices"));
const SettingsMasterDataElectricity = React.lazy(() => import("./container/settings/master-data/Electricity"));
const SettingsMasterDataHotel = React.lazy(() => import("./container/settings/master-data/Hotel"));
// master data

// brsr
const BRSRList = React.lazy(() => import('./container/brsr/List'));
const BRSRDetail = React.lazy(() => import('./container/brsr/Detail'));
const BRSRPrintDetail = React.lazy(() => import('./container/brsr/Print'));
// brsr

const AdminRoute = (props: { component: any, path: string }) => {
  let activeBranchId = getStorage(constant.key.activeBranch);
  const branches = getStorage(constant.key.permission);
  let permissionList = [];
  if (activeBranchId !== "") {
    const branchDetail = branches.find((i: any) => i.branch_id === parseFloat(activeBranchId));
    permissionList = branchDetail.permission_ids.split(',');
  } else {
    activeBranchId = getStorage(constant.key.activeBranch);
    const branchDetail = branches.find((i: any) => i.branch_id === parseFloat(activeBranchId));
    permissionList = branchDetail.permission_ids.split(',');
  }

  const token = getStorage(constant.key.token);
  const path = props.path;
  const urlKeys = Object.entries(constant.component);
  const match = urlKeys.filter((i: any) => i[1].url === path);
  const loginURL = constant.component.login.url + `?return_url=${encodeURI(props.path)}`

  if (token) {
    if (match.length > 0 && match[0][1].url === path) {
      const permissionId = match[0][1].permission;
      if (permissionList.indexOf(permissionId) > -1) {
        return <props.component />
      } else {
        return <FourZeroFour />
      }
    } else {
      return <FourZeroFour />
    }
  } else {
    return <Navigate to={loginURL} />
  }
};

const PrivateRoute = (props: { component: any, path: string }) => {
  const token = getStorage(constant.key.token);
  const loginURL = constant.component.login.url + `?return_url=${encodeURI(props.path)}`
  return token ? <props.component /> : <Navigate to={loginURL} />;
};

function Router() {
  return (
    <BrowserRouter>
      <Wrapper>
        <Suspense fallback={<div>Loading</div>}>
          <Routes>
            <Route path="*" element={<FourZeroFour />} />

            <Route path={constant.component.greenAPIMasterDataFuelType.url} element={<GreenAPIMasterDataFuelType />} />
            <Route path={constant.component.greenAPIMasterDataGasType.url} element={<GreenAPIMasterDataGasType />} />
            <Route path={constant.component.greenAPIMasterDataWasteType.url} element={<GreenAPIMasterDataWasteType />} />
            <Route path={constant.component.greenAPIMasterDataElectricity.url} element={<GreenAPIMasterDataElectricity />} />
            <Route path={constant.component.greenAPIMasterDataGoodsAndServices.url} element={<GreenAPIMasterDataGoodsAndServices />} />
            <Route path={constant.component.greenAPIMasterDataTransportation.url} element={<GreenAPIMasterDataTransportation />} />
            <Route path={constant.component.greenAPIMasterDataHotel.url} element={<GreenAPIMasterDataHotel />} />

            <Route path={constant.component.greenAPIEmissionFactorFuelType.url} element={<GreenAPIEmissionFactorFuelType />} />
            <Route path={constant.component.greenAPIEmissionFactorGasType.url} element={<GreenAPIEmissionFactorGasType />} />
            <Route path={constant.component.greenAPIEmissionFactorWasteType.url} element={<GreenAPIEmissionFactorWasteType />} />
            <Route path={constant.component.greenAPIEmissionFactorElectricity.url} element={<GreenAPIEmissionFactorElectricity />} />
            <Route path={constant.component.greenAPIEmissionFactorGoodsAndServices.url} element={<GreenAPIEmissionFactorGoodsAndServices />} />
            <Route path={constant.component.greenAPIEmissionFactorTransportation.url} element={<GreenAPIEmissionFactorTransportation />} />
            <Route path={constant.component.greenAPIEmissionFactorHotel.url} element={<GreenAPIEmissionFactorHotel />} />

            {/* auth */}
            <Route path={constant.component.home.url} element={<Login />} />
            <Route path={constant.component.register.url} element={<Register />} />
            <Route path={constant.component.login.url} element={<Login />} />
            <Route path={constant.component.logout.url} element={<Logout />} />
            <Route path={constant.component.profile.url} element={<Profile />} />
            {/* auth */}

            {/* brsr */}
            <Route path={constant.component.brsReport.url} element={<AdminRoute path={constant.component.brsReport.url} component={BRSRList} />} />
            <Route path={constant.component.brsReport.url} element={<BRSRList />} />
            <Route path={constant.component.brsReportDetail.url} element={<BRSRDetail />} />
            <Route path={constant.component.brsrPrintDetail.url} element={<BRSRPrintDetail />} />
            {/* brsr */}

            {/* settings */}
            {/* <Route path={constant.component.settingsMasterDataEmissionSource.url} element={<SettingsMasterDataEmissionSource />} /> */}

            <Route path={constant.component.settingsConfigPhysicalLocation.url} element={<SettingsConfigPhysicalLocation />} />
            <Route path={constant.component.settingsConfigReportingLocation.url} element={<SettingsConfigReportingLocation />} />
            <Route path={constant.component.settingsConfigSupplier.url} element={<SettingsConfigSupplier />} />

            <Route path={constant.component.settingsEmissionSourceScope1.url} element={<SettingsEmissionSourceScope1 />} />
            <Route path={constant.component.settingsEmissionSourceScope2.url} element={<SettingsEmissionSourceScope2 />} />
            <Route path={constant.component.settingsEmissionSourceScope3.url} element={<SettingsEmissionSourceScope3 />} />

            {/* master data */}
            <Route path={constant.component.settingsMasterDataFuelType.url} element={<SettingsMasterDataFuelType />} />
            <Route path={constant.component.settingsMasterDataGasType.url} element={<SettingsMasterDataGasType />} />
            <Route path={constant.component.settingsMasterDataWasteType.url} element={<SettingsMasterDataWasteType />} />
            <Route path={constant.component.settingsMasterDataTransportation.url} element={<SettingsMasterDataTransportation />} />
            <Route path={constant.component.settingsMasterDataGoodsAndServices.url} element={<SettingsMasterDataGoodsAndServices />} />
            <Route path={constant.component.settingsMasterDataElectricity.url} element={<SettingsMasterDataElectricity />} />
            <Route path={constant.component.settingsMasterDataHotel.url} element={<SettingsMasterDataHotel />} />


            {/* master data */}

            {/* emission factor */}
            <Route path={constant.component.settingsEmissionFactorElectricity.url} element={<SettingsEmissionFactorElectricity />} />
            <Route path={constant.component.settingsEmissionFactorFuelType.url} element={<SettingsEmissionFactorFuelType />} />
            <Route path={constant.component.settingsEmissionFactorGasType.url} element={<SettingsEmissionFactorGasType />} />
            <Route path={constant.component.settingsEmissionFactorGoodsAndServices.url} element={<SettingsEmissionFactorGoodsAndServices />} />
            {/* <Route path={constant.component.settingsEmissionFactorCapitalGoods.url} element={<SettingsEmissionFactorCapitalGoods />} /> */}
            <Route path={constant.component.settingsEmissionFactorTransportation.url} element={<SettingsEmissionFactorTransportation />} />
            <Route path={constant.component.settingsEmissionFactorWasteType.url} element={<SettingsEmissionFactorWasteType />} />
            {/* emission factor */}
            {/* settings */}

            {/* support ticket */}
            <Route path={constant.component.supportTicketList.url} element={<SupportTicketList />} />
            <Route path={constant.component.supportTicketAdd.url} element={<SupportTicketAdd />} />
            <Route path={constant.component.supportTicketDetail.url} element={<SupportTicketDetail />} />
            {/* support ticket */}

            {/* misc */}
            <Route path={constant.component.welcome.url} element={<PrivateRoute path={constant.component.welcome.url} component={Welcome} />} />
            <Route path={constant.component.comingSoon.url} element={<ComingSoon />} />
            {/* misc */}

            <Route path={constant.component.esgDetail.url} element={<PrivateRoute path={constant.component.esgDetail.url} component={ESGDetail} />} />
            <Route path={constant.component.esgCapture.url} element={<PrivateRoute path={constant.component.esgCapture.url} component={ESGCaptureDetail} />} />

            {/* ghg */}
            <Route path={constant.component.ghgStationaryEmission.url} element={<StationaryEmission />} />
            <Route path={constant.component.ghgMobileCombustion.url} element={<MobileCombustionEmission />} />
            <Route path={constant.component.ghgFugitive1Emission.url} element={<Fugitive1Emission />} />
            <Route path={constant.component.ghgFugitive2Emission.url} element={<Fugitive2Emission />} />
            <Route path={constant.component.ghgFugitive3Emission.url} element={<Fugitive3Emission />} />

            <Route path={constant.component.ghgPurchasedElectricity.url} element={<ElectricityEmission />} />

            <Route path={constant.component.ghgCategory1Emission.url} element={<Category1Emission />} />
            <Route path={constant.component.ghgCategory2Emission.url} element={<Category2Emission />} />
            <Route path={constant.component.ghgCategory3Emission.url} element={<Category3Emission />} />
            <Route path={constant.component.ghgCategory4Emission.url} element={<Category4Emission />} />
            <Route path={constant.component.ghgCategory5Emission.url} element={<Category5Emission />} />
            <Route path={constant.component.ghgCategory6FlightEmission.url} element={<Category6FlightEmission />} />
            <Route path={constant.component.ghgCategory6RailEmission.url} element={<Category6RailEmission />} />
            <Route path={constant.component.ghgCategory6CarEmission.url} element={<Category6CarEmission />} />
            <Route path={constant.component.ghgCategory6HotelEmission.url} element={<Category6HotelEmission />} />
            <Route path={constant.component.ghgCategory7Emission.url} element={<Category7Emission />} />
            <Route path={constant.component.ghgCategory8Emission.url} element={<Category8Emission />} />
            <Route path={constant.component.ghgCategory11Emission.url} element={<Category11Emission />} />
            <Route path={constant.component.ghgCategory12Emission.url} element={<Category12Emission />} />
            <Route path={constant.component.ghgCategory13Emission.url} element={<ComingSoon />} />
            <Route path={constant.component.ghgCategory14Emission.url} element={<ComingSoon />} />
            <Route path={constant.component.ghgCategory15Emission.url} element={<ComingSoon />} />
            {/* ghg */}

            {/* compliance */}
            <Route path={constant.component.complianceGRI.url} element={<ComplianceGRI />} />
            {/* compliance */}

            {/* ghg dashboard */}
            <Route path={constant.component.ghgOrganisationEmissionDashboard.url} element={<OrganisationEmissionDashboard />} />
            <Route path={constant.component.ghgBranchEmissionDashboard.url} element={<BranchEmissionDashboard />} />
            {/* ghg dashboard */}

            {/* esg dashboard */}
            <Route path="/esg/template" element={<ESGChartTemplate />} />
            <Route path={constant.component.esgWasteDashboard.url} element={<ESGEnvironmentWasteDashboard />} />
            <Route path={constant.component.esgWaterDashboard.url} element={<ESGEnvironmentWaterDashboard />} />
            <Route path={constant.component.esgEnergyDashboard.url} element={<ESGEnvironmentEnergyDashboard />} />

            <Route path={constant.component.esgSocialDiversityDashboard.url} element={<ESGSocialDiversityDashboard />} />
            <Route path={constant.component.esgSocialEmployementDashboard.url} element={<ESGSocialEmployementDashboard />} />
            <Route path={constant.component.esgSocialOccupationalDashboard.url} element={<ESGSocialOccupationalDashboard />} />

            <Route path={constant.component.esgGovernanceSummaryDashboard.url} element={<ESGGovernanceSummaryDashboard />} />


            <Route path={constant.component.esgMaterialConfigMaterialProfile.url} element={<ESGMaterialityConfigMaterialProfile />} />
            <Route path={constant.component.esgMaterialConfigMaterialQuestion.url} element={<ESGMaterialityConfigMaterialQuestion />} />

            <Route path={constant.component.esgMaterialConfigCategoryList.url} element={<ESGMaterialityConfigCategoryList />} />
            <Route path={constant.component.esgMaterialConfigTopicList.url} element={<ESGMaterialityConfigTopicList />} />
            <Route path={constant.component.esgMaterialConfigSubTopicList.url} element={<ESGMaterialityConfigSubTopicList />} />
            <Route path={constant.component.esgMaterialConfigQuestionList.url} element={<ESGMaterialityConfigQuestionList />} />
            {/* esg dashboard */}

            {/* offset */}
            <Route path={constant.component.offsetMarketPlace.url} element={<OffsetMarketPlace />} />
            <Route path={constant.component.offsetCart.url} element={<OffsetCart />} />
            <Route path={constant.component.offsetCheckout.url} element={<OffsetCheckout />} />
            <Route path={constant.component.offsetPurchaseHistory.url} element={<OffsetPurchaseHistory />} />
            <Route path={constant.component.offsetFavourite.url} element={<OffsetFavourite />} />
            <Route path={constant.component.offsetDetail.url} element={<OffsetDetail />} />
            {/* offset */}

            {/* action library */}
            <Route path={constant.component.decarbonizationActionLibrary.url} element={<DecarbonizationActionLibrary />} />
            <Route path={constant.component.decarbonizationNetZeroGoalTracker.url} element={<DecarbonizationNetZeroGoalTracker />} />
            <Route path={constant.component.decarbonizationNetZeroRoadMap.url} element={<DecarbonizationNetZeroRoadmap />} />
            {/* action library */}

            {/* settings */}
            <Route path={constant.component.manageRole.url} element={<ManageRole />} />
            <Route path={constant.component.manageUser.url} element={<ManageUser />} />
            <Route path={constant.component.manageCompanyBranch.url} element={<ManageCompanyBranch />} />
            {/* settings */}

          </Routes>
        </Suspense>
      </Wrapper>
    </BrowserRouter>
  )
}

export default Router;