import { atom, } from 'recoil';
import { getStorage } from '../utils';
import constant from '../constant';

const locationList = [
    { id: 1, name: 'Golden Ridge Oil Field' },
    { id: 2, name: 'Silver Creek Oil Field' },
    { id: 3, name: 'Emerald Valley Oil Field' },
]

const emissionFactorList = [
    { id: 1, name: 'Oil & Gas' },
    { id: 2, name: 'Goal' },
    { id: 3, name: 'Agriculture Aquaculture and Fishing' },
    { id: 4, name: 'Mining' },
]

const emissionSourceList = [
    { id: 1, name: 'Oil & Gas' },
    { id: 2, name: 'Goal' },
    { id: 3, name: 'Agriculture Aquaculture and Fishing' },
    { id: 4, name: 'Mining' },
]

export const emissionFactorListAtom = atom({
    key: 'emissionFactorListAtom',
    default: emissionFactorList
});

export const emissionSourceListAtom = atom({
    key: 'emissionSourceListAtom',
    default: emissionSourceList
});

export const locationListAtom = atom({
    key: 'locationListAtom',
    default: locationList
});

export const branchListAtom = atom({
    key: 'branchListAtom',
    default: []
});

const activeBranch = getStorage(constant.key.activeBranch);
export const activeBranchListAtom = atom({
    key: 'activeBranchListAtom',
    default: activeBranch ? activeBranch : ''
});

// export const languageListAtom = atom({
//     key: 'languageListAtom',
//     default: []
// });