import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { getStorage, removeStorage, setStorage } from '../utils';
import constant from '../constant';
import i18n from "./../i18n";

export const UIContext = React.createContext({});

export const UIContextProvider = (props: any) => {
    const [showSideBar, setShowSideBar] = useState(false);
    const [showHeader, setShowHeader] = useState(false);

    const getUserDetail = () => {
        const userDetail = getStorage(constant.key.userDetail);
        let activeBranchId = getStorage(constant.key.activeBranch);
        const userPermissionList = getStorage(constant.key.permission);

        let userBranchList: any = [];
        if (userDetail) {
            userBranchList = userPermissionList.map((i: any) => { return { name: i.branch_name, id: i.branch_id } });
        }
        let activeBranchDetail = null;
        if (activeBranchId) {
            activeBranchDetail = userPermissionList.find((i: any) => i.branch_id.toString() === activeBranchId.toString())
        } else if (!activeBranchId && userBranchList.length > 0) {
            activeBranchId = userBranchList[0].id;
            setStorage(constant.key.activeBranch, activeBranchId);
            activeBranchDetail = userPermissionList.find((i: any) => i.branch_id.toString() === activeBranchId.toString())
        }

        userDetail.userBranchList = userBranchList;
        userDetail.activeBranchDetail = activeBranchDetail;
        return userDetail;
    }

    return (
        <UIContext.Provider value={{
            toast,
            getUserDetail,
            showSideBar, setShowSideBar,
            showHeader, setShowHeader
        }}>
            {props.children}
            <Toaster
                position="bottom-right"
                toastOptions={{
                    // Define default options
                    // className: '',
                    duration: 5000,
                    // style: {
                    //     background: '#363636',
                    //     color: '#fff',
                    // },

                    // Default options for specific types
                    // success: {
                    //     duration: 3000,
                    //     theme: {
                    //         primary: 'green',
                    //         secondary: 'black',
                    //     },
                    // },
                }}
            />
        </UIContext.Provider>
    )
}

const WithUIContext = (Component: any) => {
    return (props: any) => (
        <UIContext.Consumer>
            {(value: any) => <Component {...props} context={value} />}
        </UIContext.Consumer>
    )
}

export default WithUIContext;