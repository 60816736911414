import { useState } from "react";

export default function PasswordInput(props: { value: any, placeholder: any, onChange: any, name: any, css: any }) {
    const { name, value, placeholder, onChange, css } = props;
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div className="input-group">
            <input name={name} type={showPassword ? "text" : "password"} className={css} id="signin-password" placeholder={placeholder} value={value} onChange={onChange} />
            <button onClick={() => setShowPassword(!showPassword)} className="btn btn-light" type="button" id="button-addon2">
                {
                    showPassword
                        ? <i className="ti ti-eye align-middle"></i>
                        : <i className="ti ti-eye-off align-middle"></i>
                }

            </button>
        </div>
    )
}