import { useEffect, useContext } from "react";
import { UIContext } from "../../context/UIContext";
import constant from "../../constant";

function Home() {
    const title = constant.component.welcome.title;
    const { setShowSideBar, setShowHeader, getUserDetail }: any = useContext(UIContext);
    const ud = getUserDetail();

    useEffect(() => {
        document.title = title;
        setShowSideBar(true);
        setShowHeader(true);
    }, []);

    return (
        <>
            <div className="container-fluid">
                {/* title */}
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Welcome, {ud.name}</h1>
                    {/* <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">GHG</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Emission</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div> */}
                </div>
                {/* title */}

            </div>
        </>
    )
}

export default Home;