function Home() {
    document.title = "Coming Soon";
    return (
        <>
            <div className="container-fluid">
                {/* title */}
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    {/* <h1 className="page-title fw-semibold fs-18 mb-0">Welcome</h1> */}
                    {/* <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">GHG</a></li>
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Emission</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div> */}
                </div>
                {/* title */}

                <div className="row">
                    <div className="text-center p-5 my-auto">
                        <div className="row align-items-center justify-content-center h-100">
                            <div className="col-xl-7">
                                <p className="fw-bold fs-2 mb-sm-0 mb-2">Coming Soon</p>
                                {/* <p className="fs-18 fw-semibold mb-3">The page you are looking for is in Development.</p> */}
                                <div className="row justify-content-center mb-5">
                                    <div className="col-xl-6">
                                        <p className="mb-0 op-7">We are sorry for the inconvenience but the page you are looking for is in Development</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;