import moment from "moment";
import constant from "./constant";

export const setSessionStorage = (key: string, jsonObj: any) => {
    sessionStorage.setItem(key, JSON.stringify(jsonObj));
};

// const rememberMe = () => {
//     const item: any = localStorage.getItem(constant.key.rememberMe);
//     return JSON.parse(item);
// }

export const setStorage = (key: string, jsonObj: any) => {
    // if (rememberMe()) {
    localStorage.setItem(key, JSON.stringify(jsonObj));
    // } else {
    //     sessionStorage.setItem(key, JSON.stringify(jsonObj));
    // }
};

export const getStorage = (keyName: string) => {
    // if (rememberMe()) {
    const item: any = localStorage.getItem(keyName);
    return JSON.parse(item);
    // } else {
    //     const item: any = sessionStorage.getItem(keyName);
    //     return JSON.parse(item);
    // }
};

export const removeStorage = (keyName: string) => {
    localStorage.removeItem(keyName);
    // sessionStorage.removeItem(keyName);
};

export const getAuthHeader = () => {
    const token = getStorage(constant.key.token);
    const header = {
        headers: { Authorization: "Bearer " + token },
    };
    return header;
};

export const getUploadFileHeader = () => {
    const token = getStorage(constant.key.token);
    const header = {
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data;",
        },
    };
    return header;
};

export const setStatePromise = (context: any, name: string, value: any) => {
    try {
        context.setState({ [name]: value }, () => {
            return Promise.resolve(true);
        });
    } catch (err) {
        return Promise.reject(err);
    }
};

export const onChange = (
    context: any,
    name: string,
    newValue: any,
    callback?: any
) => {
    context.setState(
        { [name]: { ...context.state[name], value: newValue } },
        callback && callback
    );
};

export const onChangePromise = (context: any, name: string, newValue: any) => {
    try {
        context.setState(
            { [name]: { ...context.state[name], value: newValue } },
            () => {
                return Promise.resolve(true);
            }
        );
    } catch (err) {
        return Promise.reject(err);
    }
};

export const setOptions = (
    context: any,
    name: string,
    value: any,
    callback?: any
) => {
    context.setState(
        { [name]: { ...context.state[name], options: value } },
        callback && callback
    );
};

export const setOptionsPromise = (context: any, name: string, value: any) => {
    try {
        context.setState(
            { [name]: { ...context.state[name], options: value } },
            () => {
                return Promise.resolve(true);
            }
        );
    } catch (err) {
        return Promise.reject(err);
    }
};

export const setError = (
    context: any,
    name: string,
    error: string,
    callback?: any
) => {
    context.setState(
        { [name]: { ...context.state[name], error } },
        callback && callback
    );
};

export const setErrorPromise = (context: any, name: string, error: string) => {
    try {
        context.setState({ [name]: { ...context.state[name], error } }, () => {
            return Promise.resolve(true);
        });
    } catch (err) {
        return Promise.reject(err);
    }
};

export const setRequired = (
    context: any,
    name: string,
    required: boolean,
    callback?: any
) => {
    context.setState(
        { [name]: { ...context.state[name], required } },
        callback && callback
    );
};

export const setRequiredPromise = (
    context: any,
    name: string,
    required: boolean
) => {
    try {
        context.setState({ [name]: { ...context.state[name], required } }, () => {
            return Promise.resolve(true);
        });
    } catch (err) {
        return Promise.reject(err);
    }
};

export const validateForm = (context: any) => {
    const st = JSON.parse(JSON.stringify(context.state));
    let status = true;

    for (let key in st) {
        if (st.hasOwnProperty(key) && st[key]) {
            const name = st[key].name;
            const required = st[key].required;
            const value = st[key].value;
            const type = st[key].type;
            if (required) {
                switch (type) {
                    case constant.fieldType.string:
                        if (value === null || value === undefined) {
                            setError(context, name, "This field is required");
                            status = false;
                            continue;
                        }

                        if (value.length === 0) {
                            setError(context, name, "This field is required");
                            status = false;
                            continue;
                        } else {
                            setError(context, name, "");
                            continue;
                        }
                    case constant.fieldType.array:
                        if (value === null || value === undefined) {
                            setError(context, name, "This field is required");
                            status = false;
                            continue;
                        }

                        if (value.length === 0) {
                            setError(context, name, "This field is required");
                            status = false;
                            continue;
                        } else {
                            setError(context, name, "");
                            continue;
                        }
                    case constant.fieldType.object:
                        if (value === undefined || value === null || value.length === 0) {
                            setError(context, name, "This field is required");
                            status = false;
                            continue;
                        } else {
                            setError(context, name, "");
                            continue;
                        }
                    default:
                        setError(context, name, "This field is required");
                        status = false;
                        continue;
                }
            }
        }
    }
    return status;
};

export const sleep = (seconds: any) => {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

export const sleepMS = (milliseconds: any) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const dynamicSort = (property: any) => {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a: any, b: any) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
};

export const getRandomColorCSS = (index?: number) => {
    const colorsCSS = [
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
        "bg-primary",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-secondary",
    ];
    if (index !== undefined && index !== null) {
        return colorsCSS[index];
    }
    return colorsCSS[Math.floor(Math.random() * colorsCSS.length) + 1];
};

// export const getUniqueArray = (a: any) =>
//   [...new Set(a.map((o: any) => JSON.stringify(o)))].map((s: any) =>
//     JSON.parse(s)
//   );

export const formatDateInYMD = (date: Date) => {
    const year = new Date(date).getFullYear();
    let month: string | number = new Date(date).getMonth();
    if (month < 10) {
        month = "0" + month;
    }
    let day: string | number = new Date(date).getDate();
    if (day < 10) {
        day = "0" + day;
    }
    return `${year}-${month}-${day}`;
};

export const createDateRange = (month: number) => {
    let dateRange = new Array(month)
        .fill("1")
        .map((data, index) => {
            const monthToSubtract = index + 1;
            const month = moment()
                .subtract(monthToSubtract, "month")
                .format("YYYY-MM");
            return `'${month}'`;
        })
        .toString();
    return dateRange;
};

// export const roundOfDecimalToTwo = (number: number) => {
//   return Math.round(number * 100) / 100;
// };

export const replaceComma = (_input: any) => {
    const input = _input.toString().replace(/,/g, '');
    return input;
};

export const getFormattedNumber = (_input: any) => {
    const input = _input.toString().replace(/,/g, '');
    const output = parseFloat(parseFloat(input).toFixed(0)).toLocaleString(undefined, { minimumFractionDigits: 0 });
    return output;
};

export const getFormattedFloat = (_input: any) => {
    const input = _input.toString().replace(/,/g, '');

    const output = parseFloat(parseFloat(input).toFixed(2)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
    });
    if (isNaN(parseFloat(output))) {
        return '0'
    }
    else if (parseFloat(output) === 0) {
        return '0'
    } else {
        return output;
    }
};

export const getFormattedPercentage = (_input: any) => {
    const input = _input.toString().replace(/,/g, '');

    const output = parseFloat(parseFloat(input).toFixed(2)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
    });
    if (isNaN(parseFloat(output))) {
        return '0'
    }
    else if (parseFloat(output) === 0) {
        return '0'
    } else if (parseFloat(output) === 100) {
        return '100'
    } else {
        return output;
    }
};

// export const getFormattedCurrency = (input: string) => {
//   return (
//     `${constant.app.currency} ` +
//     parseFloat(parseFloat(input).toFixed(2)).toLocaleString(undefined, {
//       minimumFractionDigits: 2,
//     })
//   );
// };

export function isNumber(evt: any) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}

export const checkVersion = () => {
    fetch("/version.json", { headers: { "Cache-Control": "no-cache" } })
        .then((response: any) => response.json())
        .then((data: any) => {
            const value = getStorage(constant.key.version);
            if (value !== data.version) {
                setStorage(constant.key.version, data.version);
                window.location.reload();
            }
        });
};

export function formatAmount(amount: number) {
    return amount.toLocaleString("en-US", { style: "currency", currency: "INR" });
}

export function excelDateToJSDate(date: number) {
    return moment(new Date(Math.round((date - 25569) * 86400 * 1000))).format(
        "YYYY-MM-DD"
    );
}


// export function addLibrary(urlOfTheLibrary: any) {
//   const script = document.createElement("script");
//   script.src = urlOfTheLibrary;
//   script.async = true;
//   document.body.appendChild(script);
// }

// export function onResizeSidebar(x: any) {
//   const newWidth = parseFloat(x);
//   const navbar = $('#navbarVerticalCollapse');
//   navbar.attr('style', `width: ${newWidth}px !important`);

//   const navbarVerticalComponent = $('.navbar-vertical-content');
//   navbarVerticalComponent.attr('style', `width: 100% !important`);

//   const content = $('.content');
//   content.attr('style', `margin-left: ${newWidth + 16}px !important`);
// }

export function setLanguageSettings(lang: any, dir: any) {
    setStorage(constant.key.language, lang);
    setStorage(constant.key.direction, dir);
}

export function getLanguageSettings() {
    let dir = getStorage(constant.key.direction);
    let lang = getStorage(constant.key.language);
    if (!dir) {
        setStorage(constant.key.direction, 'ltr');
    }
    if (!lang) {
        setStorage(constant.key.language, 'en');
    }
    lang = lang ? lang : 'en';
    dir = dir ? dir : 'ltr';
    return { lang, dir }
}

export const getDateRange = (firstDate: string, lastDate: string) => {
    if (moment(firstDate, 'YYYY-MM-DD').isSame(moment(lastDate, 'YYYY-MM-DD'), 'day'))
        return [lastDate];
    let date: any = firstDate;
    const dates = [date];
    do {
        date = moment(date).add(1, 'day');
        dates.push(date.format('YYYY-MM-DD'));
    } while (moment(date).isBefore(lastDate));
    return dates;
};

export const checkCaptureAndApprovalPermission = (userDetail: any, capturePermissionId: string, approvalPermissionId: string) => {
    let pm: any = [];
    if (userDetail.activeBranchDetail) {
        pm = userDetail.activeBranchDetail.permission_ids.split(',');
    }
    const capturePermission = pm.filter((i: any) => i === capturePermissionId.toString()).length > 0;
    const approvalPermission = pm.filter((i: any) => i === approvalPermissionId.toString()).length > 0;
    return { capturePermission, approvalPermission }
}