import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Draggable from 'react-draggable';

interface IPropType {
    css?: string,
    backdrop: "static" | true | false,
    onHideModal: any,
    title?: string,
    children: any,
    size?: any
}

function ModalWindow(props: IPropType) {
    const { css, backdrop, onHideModal } = props;
    const backdropAttribute = backdrop ? 'static' : true;
    const toggle = () => {
        onHideModal();
    };

    return (
        <Draggable
            // axis="x"
            handle=".handle"
        // defaultPosition={{ x: 0, y: 0 }}
        // position={null}
        // grid={[25, 25]}
        // scale={1}
        // onStart={this.handleStart}
        // onDrag={this.handleDrag}
        // onStop={this.handleStop}
        >
            <Modal size={props.size} isOpen={true} toggle={toggle} className={css} backdrop={backdropAttribute}>
                <ModalHeader className="handle" toggle={toggle}>{props.title}</ModalHeader>
                <ModalBody>
                    {props.children}
                </ModalBody>
            </Modal>
        </Draggable>
    );
}

export default ModalWindow;