const keyPrefix = 'esg-pro-web';

const key = {
    rememberMe: keyPrefix + '.remember-me',
    token: keyPrefix + '.token',
    permission: keyPrefix + '.permission',
    userDetail: keyPrefix + '.user-detail',
    version: keyPrefix + '.version',
    activeBranch: keyPrefix + '.branch',
    language: keyPrefix + '.language',
    direction: keyPrefix + '.direction',
    themeRTL: 'ynexrtl',
    openapi: 'sk-proj-4kvDOkAvu0gYNjqI7C85T3BlbkFJJlYEE5Fo1RSXxu3GMuOW',
    laama: 'LL-9ynTwgUKaM1JwSQTAGl5ifVOcq0T4uycF3QqdIEqQf2o9UPWKraR7zP9ZnyLszPV'
}

const languageList = [
    { id: 'en', name: 'English', dir: "ltr" },
    { id: 'hi', name: 'हिन्दी', dir: "ltr" },
    { id: 'ar', name: 'العربية', dir: "rtl" },
]

const permission = {
    public: "0",

    scManageGreenAPI: '1',
    scManageUserManagement: "2",
    scManageLocationManagement: "3",
    scManageMasterData: "4",
    scManageEmissionFactor: "5",
    scManageGeneralSettings: "6",
    crGHGReport: "7",
    crBRSRReport: "8",
    crGRIReport: "9",
    oeMarketplace: "10",
    oeFavouriteProject: "11",
    oePurchaseHistory: "12",
    dcActionLibrary: "13",
    dcNetZeroRoadmap: "14",
    dcNetZeroGoalTracker: "15",
    ghgViewDashboard: "16",
    ghgCaptureStationaryEmission: "17",
    ghgCaptureFugitiveEmission: "18",
    ghgCaptureMobileEmission: "19",
    ghgCapturePurchasedElectricityEmission: "20",
    ghgCaptureCategory1Emission: "21",
    ghgCaptureCategory2Emission: "22",
    ghgCaptureCategory4Emission: "23",
    ghgCaptureCategory5Emission: "24",
    ghgCaptureCategory6Emission: "25",
    ghgCaptureCategory7Emission: "26",
    ghgCaptureCategory8Emission: "27",
    ghgCaptureCategory11Emission: "45",
    ghgCaptureCategory12Emission: "47",
    ghgCaptureCategory13Emission: "49",
    ghgCaptureCategory14Emission: "51",
    ghgCaptureCategory15Emission: "53",

    ghgAuditStationaryEmission: "28",
    ghgAuditFugitiveEmission: "29",
    ghgAuditMobileEmission: "30",
    ghgAuditPurchasedElectricityEmission: "31",
    ghgAuditCategory1Emission: "32",
    ghgAuditCategory2Emission: "33",
    ghgAuditCategory3Emission: "44",
    ghgAuditCategory4Emission: "34",
    ghgAuditCategory5Emission: "35",
    ghgAuditCategory6Emission: "36",
    ghgAuditCategory7Emission: "37",
    ghgAuditCategory8Emission: "38",
    ghgAuditCategory11Emission: "46",
    ghgAuditCategory12Emission: "48",
    ghgAuditCategory13Emission: "50",
    ghgAuditCategory14Emission: "52",
    ghgAuditCategory15Emission: "54",
    esgViewDashboard: "39",
    esgCaptureEnvironmentData: "40",
    esgCaptureSocialData: "41",
    esgCaptureGovernanceData: "42",
    esgMaterialityConfig: "43"
};

const component = {
    home: { url: '/', title: 'Home', permission: permission.public },
    comingSoon: { url: '/coming-soon', title: 'Coming Soon', permission: permission.public },

    // auth
    login: { url: '/login', title: 'Login', permission: permission.public },
    logout: { url: '/logout', title: 'Logout', permission: permission.public },
    forgotPassword: { url: '/forgot-password', title: 'Forgot Password', permission: permission.public },
    resetPassword: { url: '/reset-password', title: 'Reset Password', permission: permission.public },
    register: { url: '/register', title: 'Register', permission: permission.public },
    // auth

    brsReport: { url: '/brsr', title: 'BUSINESS RESPONSIBILITY & SUSTAINABILITY REPORTING', permission: permission.crBRSRReport },
    brsReportDetail: { url: '/brsr/detail', title: 'BUSINESS RESPONSIBILITY & SUSTAINABILITY REPORTING', permission: permission.crBRSRReport },
    brsrPrintDetail: { url: '/brsr/print', title: 'BUSINESS RESPONSIBILITY & SUSTAINABILITY REPORTING', permission: permission.crBRSRReport },

    // green api
    greenAPIMasterDataFuelType: { url: '/green-api/master-data/fuel-type', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIMasterDataGasType: { url: '/green-api/master-data/gas-type', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIMasterDataWasteType: { url: '/green-api/master-data/waste-type', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIMasterDataTransportation: { url: '/green-api/master-data/transportation', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIMasterDataElectricity: { url: '/green-api/master-data/electricity', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIMasterDataHotel: { url: '/green-api/master-data/hotel', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIMasterDataGoodsAndServices: { url: '/green-api/master-data/goods-and-services', title: 'Emission Source - Master Data', permission: permission.public },

    greenAPIEmissionFactorFuelType: { url: '/green-api/emission-factor/fuel-type', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIEmissionFactorGasType: { url: '/green-api/emission-factor/gas-type', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIEmissionFactorWasteType: { url: '/green-api/emission-factor/waste-type', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIEmissionFactorTransportation: { url: '/green-api/emission-factor/transportation', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIEmissionFactorElectricity: { url: '/green-api/emission-factor/electricity', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIEmissionFactorHotel: { url: '/green-api/emission-factor/hotel', title: 'Emission Source - Master Data', permission: permission.public },
    greenAPIEmissionFactorGoodsAndServices: { url: '/green-api/emission-factor/goods-and-services', title: 'Emission Source - Master Data', permission: permission.public },
    // green api

    // settings
    settingsMasterDataEmissionSource: { url: '/settings/master-data/emission-source', title: 'Emission Source - Master Data', permission: permission.public },
    settingsEmissionSourceScope1: { url: '/settings/emission-source/scope-1', title: 'Scope 1 - Emission Source', permission: permission.public },
    settingsEmissionSourceScope2: { url: '/settings/emission-source/scope-2', title: 'Scope 2 - Emission Source', permission: permission.public },
    settingsEmissionSourceScope3: { url: '/settings/emission-source/scope-3', title: 'Scope 3 - Emission Source', permission: permission.public },


    settingsMasterDataFuelType: { url: '/settings/master-data/fuel-type', title: 'Fuel Type - Master Data', permission: permission.public },
    settingsMasterDataGasType: { url: '/settings/master-data/gas-type', title: 'Gas Type - Master Data', permission: permission.public },
    settingsMasterDataWasteType: { url: '/settings/master-data/waste-type', title: 'Waste Type - Master Data', permission: permission.public },
    settingsMasterDataTransportation: { url: '/settings/master-data/transportation', title: 'Transportation - Master Data', permission: permission.public },
    settingsMasterDataGoodsAndServices: { url: '/settings/master-data/goods-and-services', title: 'Goods & Services - Master Data', permission: permission.public },
    settingsMasterDataElectricity: { url: '/settings/master-data/electricity', title: 'Electricity - Master Data', permission: permission.public },
    settingsMasterDataHotel: { url: '/settings/master-data/hotel', title: 'Hotel Stay - Master Data', permission: permission.public },


    settingsEmissionFactorFuelType: { url: '/settings/emission-factor/fuel-type', title: 'Fuel Type - Emission Factor', permission: permission.public },
    settingsEmissionFactorGasType: { url: '/settings/emission-factor/gas-type', title: 'Gas Type - Emission Factor', permission: permission.public },
    settingsEmissionFactorWasteType: { url: '/settings/emission-factor/waste-type', title: 'Waste - Emission Factor', permission: permission.public },
    settingsEmissionFactorElectricity: { url: '/settings/emission-factor/electricity', title: 'Electricity - Emission Factor', permission: permission.public },
    settingsEmissionFactorTransportation: { url: '/settings/emission-factor/transportation', title: 'Transportation - Emission Factor', permission: permission.public },
    settingsEmissionFactorGoodsAndServices: { url: '/settings/emission-factor/goods-and-services', title: 'Goods & Services - Emission Factor', permission: permission.public },
    // settingsEmissionFactorCapitalGoods: { url: '/settings/emission-factor/capital-goods', title: 'Spend Based - Emission Factor', permission: permission.public },

    settingsConfigPhysicalLocation: { url: '/settings/config/physical-location', title: 'Physical Location', permission: permission.public },
    settingsConfigReportingLocation: { url: '/settings/config/reporting-location', title: 'Reporting Location', permission: permission.public },
    settingsConfigSupplier: { url: '/settings/config/supplier', title: 'Supplier', permission: permission.public },
    // settings

    // profile
    welcome: { url: '/welcome', title: 'Welcome', permission: permission.public },
    profile: { url: '/profile', title: 'Profile', permission: permission.public },
    changePassword: { url: '/change-password', title: 'Change Password', permission: permission.public },
    // profile

    // support
    supportTicketList: { url: '/support-ticket/list', title: 'View Support Ticket', permission: permission.public },
    supportTicketAdd: { url: '/support-ticket/add', title: 'Add Support Ticket', permission: permission.public },
    supportTicketDetail: { url: '/support-ticket/detail/:id', title: 'Support Ticket Detail', permission: permission.public },
    // support

    // esg
    esgDetail: { url: '/esg/detail/:id', title: 'ESG Detail', permission: permission.public },
    esgCapture: { url: '/esg/capture', title: 'ESG Capture', permission: permission.public },
    esgEnergyDashboard: { url: '/esg/environment/energy/dashboard', title: 'ESG Environment Energy Dashboard', permission: permission.public },
    esgWasteDashboard: { url: '/esg/environment/waste/dashboard', title: 'ESG Environment Waste Dashboard', permission: permission.public },
    esgWaterDashboard: { url: '/esg/environment/water/dashboard', title: 'ESG Environment Water Dashboard', permission: permission.public },

    esgSocialEmployementDashboard: { url: '/esg/social/employement/dashboard', title: 'ESG Social Employement Dashboard', permission: permission.public },
    esgSocialOccupationalDashboard: { url: '/esg/social/occupational/dashboard', title: 'ESG Social Occupational Dashboard', permission: permission.public },
    esgSocialDiversityDashboard: { url: '/esg/social/diversity/dashboard', title: 'ESG Social Diversity Dashboard', permission: permission.public },

    esgGovernanceSummaryDashboard: { url: '/esg/governance/summary/dashboard', title: 'ESG Governance Summary Dashboard', permission: permission.public },
    esgMaterialConfigMaterialProfile: { url: '/esg/material-config/material-profile', title: 'Material Profile', permission: permission.public },
    esgMaterialConfigMaterialQuestion: { url: '/esg/material-config/material-question', title: 'Material Profile', permission: permission.public },

    esgMaterialConfigCategoryList: { url: '/esg/material-config/category', title: 'Category List', permission: permission.public },
    esgMaterialConfigTopicList: { url: '/esg/material-config/topic', title: 'Topic List', permission: permission.public },
    esgMaterialConfigSubTopicList: { url: '/esg/material-config/subtopic', title: 'Subtopic List', permission: permission.public },
    esgMaterialConfigQuestionList: { url: '/esg/material-config/question', title: 'Question List', permission: permission.public },
    // esg

    // ghg
    ghgStationaryEmission: { url: '/ghg/emission/stationary', title: 'Stationary Emission', permission: permission.public },
    ghgMobileCombustion: { url: '/ghg/emission/mobile', title: 'Mobile Combustion', permission: permission.public },
    ghgFugitive1Emission: { url: '/ghg/emission/fugitive-1', title: 'Fugitive Emission', permission: permission.public },
    ghgFugitive2Emission: { url: '/ghg/emission/fugitive-2', title: 'Fugitive Emission', permission: permission.public },
    ghgFugitive3Emission: { url: '/ghg/emission/fugitive-3', title: 'Fugitive Emission', permission: permission.public },

    ghgPurchasedElectricity: { url: '/ghg/emission/purchased-electricity', title: 'Purchased Electricity', permission: permission.public },

    ghgCategory1Emission: { url: '/ghg/emission/category-1', title: 'Category 1: Purchased Goods and Services', permission: permission.public },
    ghgCategory2Emission: { url: '/ghg/emission/category-2', title: 'Category 2: Capital Goods', permission: permission.public },
    ghgCategory3Emission: { url: '/ghg/emission/category-3', title: 'Category 3: Fuel and Energy Related', permission: permission.public },
    ghgCategory4Emission: { url: '/ghg/emission/category-4', title: 'Category 4: Upstream Transportation and Distribution', permission: permission.public },
    ghgCategory5Emission: { url: '/ghg/emission/category-5', title: 'Category 5: Waste Generated in Operations', permission: permission.public },
    ghgCategory6FlightEmission: { url: '/ghg/emission/category-6/flight', title: 'Category 6: Business Travel', permission: permission.public },
    ghgCategory6RailEmission: { url: '/ghg/emission/category-6/rail', title: 'Category 6: Business Travel', permission: permission.public },
    ghgCategory6CarEmission: { url: '/ghg/emission/category-6/car', title: 'Category 6: Business Travel', permission: permission.public },
    ghgCategory6HotelEmission: { url: '/ghg/emission/category-6/hotel', title: 'Category 6: Business Travel', permission: permission.public },

    ghgCategory7Emission: { url: '/ghg/emission/category-7', title: 'Category 7: Employee Commutting', permission: permission.public },
    ghgCategory8Emission: { url: '/ghg/emission/category-8', title: 'Category 8: Downstream Transportation and Distribution', permission: permission.public },
    ghgCategory9Emission: { url: '/ghg/emission/category-9', title: 'Category 9', permission: permission.public },
    ghgCategory10Emission: { url: '/ghg/emission/category-10', title: 'Category 10', permission: permission.public },
    ghgCategory11Emission: { url: '/ghg/emission/category-11', title: 'Category 11', permission: permission.public },
    ghgCategory12Emission: { url: '/ghg/emission/category-12', title: 'Category 12', permission: permission.public },
    ghgCategory13Emission: { url: '/ghg/emission/category-13', title: 'Category 13', permission: permission.public },
    ghgCategory14Emission: { url: '/ghg/emission/category-14', title: 'Category 14', permission: permission.public },
    ghgCategory15Emission: { url: '/ghg/emission/category-15', title: 'Category 15', permission: permission.public },

    ghgOrganisationEmissionDashboard: { url: '/ghg/dashboard/organisation-emission', title: 'Organisation Emission Dashboard', permission: permission.public },
    ghgBranchEmissionDashboard: { url: '/ghg/dashboard/branch-emission', title: 'Branch Emission Dashboard', permission: permission.public },


    offsetMarketPlace: { url: '/offset/marketplace', title: 'Marketplace', permission: permission.public },
    offsetFavourite: { url: '/offset/favourite', title: 'Favourite', permission: permission.public },
    offsetCart: { url: '/offset/cart', title: 'Card', permission: permission.public },
    offsetCheckout: { url: '/offset/checkout', title: 'Checkout', permission: permission.public },
    offsetPurchaseHistory: { url: '/offset/purchase-history', title: 'Offsetting Purchase History', permission: permission.public },
    offsetDetail: { url: '/offset/detail/:id', title: 'Project Detail', permission: permission.public },

    decarbonizationActionLibrary: { url: '/decarbonization/action-library', title: 'Action Library', permission: permission.public },
    decarbonizationNetZeroGoalTracker: { url: '/decarbonization/net-zero-goal-tracker', title: 'Net Zero Goal Tracker', permission: permission.public },
    decarbonizationNetZeroRoadMap: { url: '/decarbonization/net-zero-road-map', title: 'Net Zero Roadmap', permission: permission.public },

    complianceGRI: { url: '/compliance/gri', title: 'GRI', permission: permission.public },

    // settings
    manageRole: { url: '/settings/role', title: 'Manage Role', permission: permission.public },
    manageUser: { url: '/settings/user', title: 'Manage User', permission: permission.public },
    manageCompanyBranch: { url: '/settings/company-branch', title: 'Manage Branch', permission: permission.public },
};

const fieldType = {
    string: 'string',
    array: 'array',
    object: 'object',
    date: 'date',
    number: 'number',
}

const dateTimeFormat = {
    dateFormat: 'DD MMM YYYY',
    timeFormat: 'hh:mm:ss A',
    dateTimeFormat: 'DD MMM YYYY hh:mm A',
};

const ghgListInitialState = { pending: undefined, approved: undefined, rejected: undefined }
const customForm = { dateForm: 'date-form', numberForm: 'number-form' }
const colors = [
    '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0',
    '#3F51B5', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800',
    '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B',
    '#4ECDC4', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A'
]

const aws = {
    s3Folder: {
        document: 'document'
    }

}
export default { aws, component, key, fieldType, dateTimeFormat, ghgListInitialState, customForm, colors, permission, languageList }